import { Routes } from '../Routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { unref } from 'vue'
import { useAuth0Store } from '@/stores/auth0Store'

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const auth0 = useAuth0Store()
    const isAuthenticated = unref(auth0.isAuthenticated)
    if (isAuthenticated) {
        return next({
            name: Routes.Home,
        })
    }
    return next()
}
