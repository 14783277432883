import { Routes } from '@/router/Routes'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { computed, unref } from 'vue'
import { useAuth0Store } from '@/stores/auth0Store'

export default (...roles: string[]) => {
    return (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
        const auth0 = useAuth0Store()
        const isAuthenticated = unref(auth0.isAuthenticated)
        const user = unref(auth0.user)
        const idToken = unref(auth0.idToken)

        if (isAuthenticated && user && idToken && roles.length > 0) {
            if (roles.some((role) => user?.roles?.includes(role)) || roles.some((role) => idToken?.role === role)) {
                return next()
            }
        }

        next({
            name: Routes.Unauthorized,
            state: { message: `You need to have one of the following roles: ${roles.join(', ')}` },
        })
    }
}
