<script setup lang="ts">
import { RouterView } from 'vue-router'
import { App, AppHeader, AppMain, HorizontalStack, LoadingSpinner } from '@inhaus-ai-limited/vue-components'
import HeaderContent from './views/components/HeaderContent.vue'
import { useAuth0Store } from './stores/auth0Store'
import { computed, unref } from 'vue'

const environment = import.meta.env.VITE_NODE_ENV
const isProduction = unref(environment) === 'prod'
const VERSION = import.meta.env.VITE_VERSION

const auth0 = useAuth0Store()
const isLoading = computed(() => unref(auth0.isLoading))

auth0.init() // Initialize the auth0 store
</script>

<template>
    <App>
        <AppHeader class="w-full shadow-md min-h-[60px] max-h-[60px] justify-between px-[40px] z-[1]">
            <HeaderContent />
        </AppHeader>
        <AppMain v-if="isLoading">
            <HorizontalStack class="w-full flex justify-center h-[100%] absolute top-[40%]">
                <LoadingSpinner size="lg" />
            </HorizontalStack>
        </AppMain>
        <RouterView v-else :key="$route.fullPath" v-slot="{ Component }" class="max-w-full max-h-full">
            <Suspense v-if="Component" suspensible>
                <component :is="Component" :key="Math.random()" />
            </Suspense>
        </RouterView>
        <div v-if="!isProduction" class="fixed bottom-0 left-0 bg-gray-500/50 text-white p-2">
            <span class="text-sm">Version: ({{ VERSION }})</span>
            <span class="text-sm">Environment: ({{ environment }})</span>
            <span class="text-sm">Loading: ({{ isLoading }})</span>
        </div>
    </App>
</template>

<style>
* {
    /* overflow: hidden; */
    /* border: solid 1px red !important; */
    box-sizing: border-box;
    font-family: 'Nunito Sans', sans-serif;
}

body,
#app {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

/**
  * Custom scrollbar
  */
::-webkit-scrollbar {
    background: transparent;
    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: black;
}
</style>
