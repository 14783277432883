import { useAuth0Store } from '@/stores/auth0Store'
import { computed, unref } from 'vue'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import { Routes } from '@/router/Routes'

export default async  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const auth0 = useAuth0Store()
    const token = await auth0.getTokenSilently();
    const isAuthenticated = unref(auth0.isAuthenticated)
    if (isAuthenticated) {
        next()
    }

    next({
        name: Routes.Unauthorized,
        state: { message: `You need to be authenticated to access ${to.fullPath}` },
    })
}
