import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { PiniaSharedState } from 'pinia-shared-state'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'

import '@inhaus-ai-limited/vue-components/lib/index.css'
import '@inhaus-ai-limited/vue-components/lib/style.css'

import './tailwind.css'

const app = createApp(App)
const pinia = createPinia()

if (import.meta.env.VITE_NODE_ENV === 'prod') {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_NODE_ENV || undefined,
        release: 'web-app@' + import.meta.env.VITE_VERSION || undefined,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['*', /^\//],
            }),
            new Sentry.Replay(),
        ],
        beforeSend(event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception) {
                Sentry.showReportDialog({ eventId: event.event_id })
            }
            return event
        },
        tracesSampleRate: 1.0,
    })
}

pinia.use(
    PiniaSharedState({
        enable: true,
        initialize: true,
        type: 'localstorage',
    })
)

pinia.use(
    createPersistedState({
        debug: true,
        auto: false,
    })
)


// pinia.use(piniaPluginPersistedstate)
app.use(pinia).use(router).config.errorHandler = (err) => {
    console.error(err)
    throw err
}

app.mount('#app')
