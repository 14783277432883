export enum Routes {
    Home = 'home',
    Photographer = 'photographer',
    PhotographerProject = 'photographer-project',
    ArtDirector = 'artdirector',
    ArtDirectorReviewReferenceImage = 'artdirector-check-reference-image',
    ArtDirectorProject = 'artdirector-project',
    ArtDirectorProjectSubmission = 'artdirector-project-submission',
    ArtDirectorRevision = 'artdirector-revision',
    ArtDirectorReshootProject = 'artdirector-reshoot-project',
    QualityAssurance = 'quality-assurance',
    QualityAssuranceDashboard = 'quality-assurance-dashboard',
    QualityAssuranceReviewReferenceImage = 'quality-assurance-review-reference-image',
    QualityAssuranceReviewSelection = 'quality-assurance-review-selection',
    QualityAssuranceReshootProject = 'quality-assurance-reshoot-project',
    AdminJobs = 'admin-jobs',
    AdminSettings = 'admin-settings',
    AdminDashboard = 'admin-dashboard',
    AdminReports = 'admin-reports',
    NotFound = 'not-found',
    Unauthorized = 'unauthorized',
    AuthCallback = 'auth-callback',
    Projects = 'projects',
    SignIn = 'login',
}

export const RouteNames: { [key: string]: string } = {
    [Routes.Home]: 'Home',
    [Routes.Photographer]: 'Photographer',
    [Routes.PhotographerProject]: 'Dashboard',
    [Routes.ArtDirector]: 'Projects',
    [Routes.ArtDirectorReviewReferenceImage]: 'Review',
    [Routes.ArtDirectorProject]: 'Project',
    [Routes.ArtDirectorProjectSubmission]: 'Submission',
    [Routes.ArtDirectorRevision]: 'Revision',
    [Routes.ArtDirectorReshootProject]: 'Reshoot',
    [Routes.QualityAssurance]: 'Dashboard',
    [Routes.QualityAssuranceDashboard]: 'Projects',
    [Routes.QualityAssuranceReviewReferenceImage]: 'Review',
    [Routes.QualityAssuranceReviewSelection]: 'Review',
    [Routes.QualityAssuranceReshootProject]: 'Reshoot',
    [Routes.AdminJobs]: 'Jobs',
    [Routes.AdminSettings]: 'Settings',
    [Routes.AdminDashboard]: 'Batch',
    [Routes.AdminReports]: 'Reports',
    [Routes.NotFound]: 'Not Found',
    [Routes.Unauthorized]: 'Unauthorized',
    [Routes.AuthCallback]: 'Auth Callback',
    [Routes.Projects]: 'Projects',
    [Routes.SignIn]: 'Log In',
}
