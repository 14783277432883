import { useAuth0Store } from '@/stores/auth0Store'
import { computed, unref } from 'vue'
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'

export default (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    const auth0 = useAuth0Store()
    const isAuthenticated = unref(auth0.isAuthenticated)
    const user = unref(auth0.user)
    const idTokenClaims = unref(auth0.idToken)
    const error = unref(auth0.error)
    const isLoading = unref(auth0.isLoading)

    console.log('auth user', user)
    console.log('auth error', error)
    console.log('auth isAuthenticated', isAuthenticated)
    console.log('auth idTokenClaims', idTokenClaims)
    console.log('auth isLoading', isLoading)
    console.log(`${String(from.name)} -> ${String(to.name)}`)
    next()
}
